@import '../../components/_var.less';

.KPI__ctn {
    border: solid 1px @color-disabled;
    border-radius: 10px;
    margin: @dim-xs;
    display: flex;
    flex-flow: row nowrap;
    padding: @dim-xs + 3px 0;

    .KPI {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: @dim-xs 0;
        flex: 1 0 0px;

        &:not(:last-child) {
            border-right: solid 1px @color-disabled;
        }

        .KPI__label {
            margin-bottom: @dim-xs;
            font-weight: 800;
            font-size: @font-xs + 0.1em;
            color: @color-black;
        }
        .KPI__numberContainer {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            column-gap: 0.1em;
            color: @color-primary;
            font-size: @font-lg + 0.3em;

            .KPI__number {
                font-weight: 800;
            }
        }
    }
}