@import '../_var.less';

@atom: Graph;

.@{atom}__ctn {

    .@{atom} {
        padding: @dim-xs;
        min-height: 220px;
        margin-right: @dim-xs;
        transition: all .2s ease-in-out;
        cursor: pointer;
        border: solid 1px rgba(0,0,0,0.05);
        position: relative;

        &:hover {
            box-shadow: 0 2px 15px -1px rgba(0, 0, 0, 0.15);
            border-color: rgba(0,0,0,0);
        }
    }

    &:last-child {
        margin-bottom: @dim-sm + @dim-xs;
    }

    .@{atom}__action {
        display: flex;
        justify-content: flex-end;
        margin: @dim-xs @dim-xs @dim-xs 0;

        .IconButton {
            margin-right: 0;
        }
    }
}
