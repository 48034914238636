@import '../_var.less';

@atom: GraphTitle;

.@{atom}__box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: @dim-sm;

    .@{atom}__image {
        @dim-img-size: 70px;

        width: @dim-img-size;
        height: @dim-img-size;
        min-height: @dim-img-size;
        margin: 0 @dim-sm @dim-xs 0;
        background: url(../../../public/ressources/logo/unhcr_branch.svg) no-repeat center center;
        background-size: @dim-img-size @dim-img-size;
        display: flex;
        justify-content: center;
        align-items: center;

        .@{atom}__icon {
            width: 30px;
            margin-bottom: 5px;
            font-size: 2.2em;
            text-indent: -2px;
            color: @color-primary;
        }
    }

    .@{atom}__title {
        text-transform: inherit;
        font-size: 1.3em;
        color: @color-black;
    }
}