@import '../../components/_var.less';

@atom: Homepage;

.Header.Header--homepage {
    background-color: @color-transparent;
    box-shadow: none;

    .Header__leftCtn .Header__titleCtn  {

        .Header__logo {
            max-width: 145px;
        }

        .Header__title {
            position: relative;
            top: 2px;

            span {
                color: @color-white;
                font-size: 1.9em;
            }
        }
    }

    @color-homepage: fade(@color-black, 60%);

    .Header__rightCtn {
        margin-top: 8px;

        .ListActionButton .Header__userLogo {
            background-color: @color-homepage;
            @dim-user-logo: 36px;
            width: @dim-user-logo;
            height: @dim-user-logo;
            min-width: @dim-user-logo;
        }
    }

    .Translation {
        color: @color-white;
        background: url("../../../public/ressources/img/expand_more_translation_white.svg") no-repeat right center;
        background-color: @color-homepage;
        background-size: 20px;
        padding: 3px 17px 3px 7px;
        top: 4px;
    }
}

.@{atom} {

    section {
        margin: 0;
    }

    .@{atom}__presentation {
        height: calc(100vh - @dim-lg);
        width: 100%;
        margin: 0;
        position: relative;
        display: flex;
        flex-flow: column nowrap;

        .@{atom}__presentationWrapper {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            height: 100%;

            .@{atom}__title {
                font-size: 3.2em;
                font-weight: 100;
                color: @color-white;
                max-width: 75%;
                line-height: 1.2em;
            }

            .@{atom}__regionCtn {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                border-top: solid 1.5px rgba(255,255,255,0.4);
                margin-top: 12vh;
                overflow-x: hidden;

                .@{atom}__region {
                    text-align: center;
                    color: @color-white;
                    padding: @dim-sm + 3px @dim-sm + 8px;
                    border-bottom: solid 3px rgba(0,0,0,0);
                    transition: border-color .3s ease-in;
                    font-size: @font-xs;
                    line-height: 1.2em;
                    font-weight: 800;
                    min-width: 90px;
                    max-width: 160px;
                    min-height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        border-color: fade(lighten(@color-primary, 5%), 80%);
                    }

                    &--active {
                        border-color: lighten(@color-primary, 5%);
                    }
                }
            }
        }

        .@{atom}__bgWrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            overflow: hidden;
            background-color: black;

            &::after {
                content: "";
                background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,.15) 55%, rgba(0,0,0,.6) 85%, #000 100%);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }

            .@{atom}__bg {
                width: 100%;
                height: auto;
                min-height: 100%;
                position: relative;
                top: -45px;
                object-fit: cover;
                z-index: -1;
                animation: scale 5s;
                animation-fill-mode: forwards;
                animation-delay: 0s;
                opacity: 0;
                transform: scale(1);
            }
        }
    }

    .@{atom}__map {
        svg, .leaflet-container{
            background: #eff8ff;
        }

        .tooltip {
            background: @color-secondary;
            border: 1.5px solid @color-primary;
            border-radius: 13px;
            color: #252525;
            padding: 3px 8px;
            opacity: .8;
            box-shadow: 0 5px 10px -5px rgba(0,0,0,0.3);
            transition: opacity .15s ease-out;
        }

        .leaflet-tooltip-left.tooltip::before,
        .leaflet-tooltip-bottom.tooltip::before,
        .leaflet-tooltip-top.tooltip::before,
        .leaflet-tooltip-right.tooltip::before {
            border-color: @color-transparent;
        }

        .leaflet-control-container .leaflet-top.leaflet-left {
            left: 14%;
            top: 47%;

            .leaflet-control {
                transform: scale(1.15);
                margin: 0;
                box-shadow: none;
                border: solid 1px #ccc;

                a {
                    color: @color-label;
                    font-weight: 400;

                    &.leaflet-disabled {
                        color: @color-placeholder;
                    }
                }
            }
        }
    }

    .KPI__ctn {
        margin-top: 0;
        top: -@dim-sm - @dim-md;
        position: relative;
        background: @color-white;
        box-shadow: 0 5px 15px -5px rgba(0,0,0,0.2);
        border: none;
        max-width: 800px;
        margin: 0 auto;
        z-index: 450;
    }

    .@{atom}__graphs {
        .@{atom}__graphTitleCtn {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.@{atom}__footer {
    display: flex;
    flex-flow: column nowrap;
    margin: @dim-lg auto !important;

    .@{atom}__topCtn {
        display: flex;
        flex-flow: row wrap;

        .@{atom}__unhcrCtn {
            display: flex;
            flex-flow: column nowrap;

            .@{atom}__unhcrLogo {
                width: 150px;
                height: 40px;
                position: relative;
                top: -@dim-sm - 5px;
                left: -@dim-sm + 5px;
            }

            .@{atom}__unhcrDesc {
                font-size: @font-xxs;
                color: @color-placeholder;
                margin-right: @dim-lg;
                line-height: 1.7em;
            }

            .@{atom}__socialCtn {
                display: flex;
                flex-flow: row nowrap;

                .@{atom}__social {

                    &:not(:last-child) {
                        margin-right: @dim-xs + 3px;
                    }

                    img {
                        color: @color-main-text;
                        height: 14px;
                        max-width: 17px;
                    }
                }
            }
        }

        .@{atom}__footerCol {
            display: flex;
            flex-flow: column nowrap;
            flex: 1;

            .@{atom}__footerTtl {
                font-weight: 800;
                margin-bottom: @dim-sm - 5px;
                font-size: @font-xs;
            }

            .@{atom}__footerLink {
                color: lighten(@color-primary, 7%);
                font-weight: 500;
                margin-bottom: @dim-xs;
                text-decoration: none;
                font-size: @font-xs;
                max-width: 90%;
                line-height: 1.5em;
                cursor: pointer;

                &:hover {
                    color: @color-main-text;
                }
            }
        }
    }

    .@{atom}__botCtn {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: @dim-sm;

        & > span {
            font-size: @font-xxs;
        }

        .@{atom}__copyright {
            &, & * {
                color: @color-placeholder;
            }

            a {
                font-weight: bold;
                text-decoration: none;
            }
        }

        .@{atom}__bywho {
            color: @color-label;
            font-weight: 600;

            a {
                text-decoration: none;
                color: @color-label;
                font-weight: 600;
            }
        }
    }
}

@keyframes scale {
    100% { opacity: 1; transform: scale(1.1); }
}